// Fonts
@font-face {
    font-family: "AlegreyaSans-Bold";
    src: url(../AlegreyaSans-Bold.otf);
}
@font-face {
    font-family: "AlegreyaSans-Medium";
    src: url(../AlegreyaSans-Medium.otf);
}
@font-face {
    font-family: "AlegreyaSans-Regular";
    src: url(../AlegreyaSans-Regular.otf);
}

// Variables
@import "_backend_variables";

// Font awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Bootstrap multiselect
@import "node_modules/bootstrap-multiselect/dist/css/bootstrap-multiselect";

// Bootstrap datepicker
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min";

// Medium Eritor
@import "node_modules/medium-editor/dist/css/medium-editor.min";
@import "node_modules/medium-editor/dist/css/themes/bootstrap.min";

body *{
  font-weight: normal;
  font-family: "AlegreyaSans-Regular", sans-serif;
}

.positive{
  color: $brand-success;
}

.negative{
  color: $brand-danger;
}

.link{
  font-size: 14px;
  color: #7da8c3;
  text-decoration: none;
  margin: 0 15px 0 0;
  cursor: pointer;

  &:hover{
    color: #6b9dbb;
  }

  &.warn{
    color: #a94442;

    &:hover{
      color: #a94442;
    }
  }
}


label{
  font-weight: normal;
  &.disabled-label{
    text-decoration: line-through;
     color: #CCCCCC;
  }
}

input[type="checkbox"]{
  margin-right: 5px;
}

.has-error{
  label{
    color: #a94442;
  }
}

.multiselect-container{
  &.dropdown-menu{
    max-height: 200px;
    overflow-y: scroll;
  }

  .dropdown-toggle{
    width: 100%;
    display: block;
  }

  li{
    a{
      padding: 5px;
      margin: 0;
    }
  }
}

.has-fa{
  text-align: center;
}

.day, .year, .month{
  color: #000;

  &.disabled{
    text-decoration: line-through;
    color: #EEE;
  }
}

.full-width {
  width: 100%;
}

.blue-background{
  background: #0b8fff;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
}
